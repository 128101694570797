<template>
  <Template
      @goHome="() => $router.push('/')"
      :order="order"
      v-bind="{
  	...params,
  }"/>

</template>

<script>
import Template from '../components/templates/CartSuccess/CartSuccess'
import {c} from '../components/constants'
import {json} from "overmind";

export default {
  components: {
    Template
  },
  data() {
    return {
      order: null,
      params: {
        ...c.cart,
        navigations: [
          {
            id: 0,
            name: 'All Categories'
          },
          {
            id: 1,
            name: 'Forming a company',
            category: 'formation sector categories',
            title: 'Forming your company',
            description: 'Our long standing vision has been to bypass the usual admin dashboard structure, and move forward with a simple framework',
            items: [
              {
                user: {
                  avatar: 'https://www.gettyimages.com/gi-resources/images/500px/983794168.jpg',
                  firstName: 'Saham Nadeem',
                  lastName: 'Anjum',
                  role: 'Chief Marketing Officer, 360 Global Health'
                },
                comment: 'Lorem ipsum, dolor sit amet consectetur adipisicinrem, inventore, dolor sit amet consectetur doloremque vero fuga.'
              },
              {
                user: {
                  avatar: 'https://www.gettyimages.com/gi-resources/images/500px/983794168.jpg',
                  firstName: 'Saham Nadeem',
                  lastName: 'Anjum',
                  role: 'Chief Marketing Officer, 360 Global Health'
                },
                comment: 'Lorem ipsum, dolor sit amet consectetur adipisicinrem, inventore, dolor sit amet consectetur doloremque vero fuga.'
              }
            ]
          },
          {
            id: 2,
            name: 'structuring a company',
            category: 'structure sector categories',
            title: 'Structuring your company',
            description: 'Our long standing vision has been to bypass the usual admin dashboard structure, and move forward with a simple framework',
            items: [
              {
                user: {
                  avatar: 'https://www.gettyimages.com/gi-resources/images/500px/983794168.jpg',
                  firstName: 'Saham Nadeem',
                  lastName: 'Anjum',
                  role: 'Chief Marketing Officer, 360 Global Health'
                },
                comment: 'Lorem ipsum, dolor sit amet consectetur adipisicinrem, inventore, dolor sit amet consectetur doloremque vero fuga.'
              },
              {
                user: {
                  avatar: 'https://www.gettyimages.com/gi-resources/images/500px/983794168.jpg',
                  firstName: 'Saham Nadeem',
                  lastName: 'Anjum',
                  role: 'Chief Marketing Officer, 360 Global Health'
                },
                comment: 'Lorem ipsum, dolor sit amet consectetur adipisicinrem, inventore, dolor sit amet consectetur doloremque vero fuga.'
              }
            ]
          },
          {
            id: 3,
            name: 'marketing a company',
            category: 'marketing sector categories',
            title: 'Marketing your company',
            description: 'Our long standing vision has been to bypass the usual admin dashboard structure, and move forward with a simple framework',
            items: [
              {
                user: {
                  avatar: 'https://www.gettyimages.com/gi-resources/images/500px/983794168.jpg',
                  firstName: 'Saham Nadeem',
                  lastName: 'Anjum',
                  role: 'Chief Marketing Officer, 360 Global Health'
                },
                comment: 'Lorem ipsum, dolor sit amet consectetur adipisicinrem, inventore, dolor sit amet consectetur doloremque vero fuga.'
              },
              {
                user: {
                  avatar: 'https://www.gettyimages.com/gi-resources/images/500px/983794168.jpg',
                  firstName: 'Saham Nadeem',
                  lastName: 'Anjum',
                  role: 'Chief Marketing Officer, 360 Global Health'
                },
                comment: 'Lorem ipsum, dolor sit amet consectetur adipisicinrem, inventore, dolor sit amet consectetur doloremque vero fuga.'
              }
            ]
          },
          {
            id: 4,
            name: 'maintaining a company',
            category: 'maintenance sector categories',
            title: 'Maintaining your company',
            description: 'Our long standing vision has been to bypass the usual admin dashboard structure, and move forward with a simple framework',
            items: [
              {
                user: {
                  avatar: 'https://www.gettyimages.com/gi-resources/images/500px/983794168.jpg',
                  firstName: 'Saham Nadeem',
                  lastName: 'Anjum',
                  role: 'Chief Marketing Officer, 360 Global Health'
                },
                comment: 'Lorem ipsum, dolor sit amet consectetur adipisicinrem, inventore, dolor sit amet consectetur doloremque vero fuga.'
              },
              {
                user: {
                  avatar: 'https://www.gettyimages.com/gi-resources/images/500px/983794168.jpg',
                  firstName: 'Saham Nadeem',
                  lastName: 'Anjum',
                  role: 'Chief Marketing Officer, 360 Global Health'
                },
                comment: 'Lorem ipsum, dolor sit amet consectetur adipisicinrem, inventore, dolor sit amet consectetur doloremque vero fuga.'
              }
            ]
          }
        ]
      },
    }
  },
  async created() {
    let order = json(this.state.order?.orders ? this.state.order?.orders[this.$route.query.id] : null);
    if (!order?.id) {
      order = json(await this.actions.order.getOrdersById(this.$route.query?.id))
    }
    this.order = order;
    console.log(order, 'order')
  }
}
</script>

<style scoped>

</style>
